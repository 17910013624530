import React, { Component } from 'react'
    
    class GoToHome extends Component {
        componentDidMount() {
            if(window !== "undefined"){
                window.location.replace("https://www.visbymedical.com/");
            }
        }
        render() {
            return <div />
        }
    }

export default GoToHome